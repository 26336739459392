import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import {outerContainer, infoContainer, mediaLinks, policyContainer} from "./contact.module.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Contact" />
    <div className={outerContainer}>
      <div className={infoContainer}>
        <p>
        Fluctua Records is an independent record label and management company based in Berlin. 
        </p>
        <div>
          <FontAwesomeIcon icon={faEnvelope} /> {' '}
          <a href="mailto:info@fluctuarecords.com" rel="noopener noreferrer" style={{color: 'black', textDecoration: 'none'}}>
            info@fluctuarecords.com
          </a>
          <div className={mediaLinks}>
            <a href="https://facebook.com/fluctuarecords" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
            </a>
            <a href="https://www.instagram.com/fluctuarecords/" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
            </a>
            <a href="https://twitter.com/FluctuaRecords" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
            </a>
            <a href="https://www.linkedin.com/company/fluctuarecords" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
            </a>
          </div>
        </div>
      
      </div>
      <div className={policyContainer}>
        <h3 style={{textAlign: 'center', fontSize: '0.8rem'}}>
          DEMO POLICY
        </h3>
        <div>
        We accept demos in line with our aesthetics.
        </div>
        <div>
        Please note that we only accept demos in digital form, preferably with a private link to a Soundcloud set: MP3 or WAV submissions via email will not be accepted. Remember to include your biography and contact info. 
        </div>
        <div>
        Do not be disappointed if we do not reply, we will contact you if we are interested in releasing your music.
        </div>
      </div>
    </div>
    
  </Layout>
)

export default IndexPage
